import { useRef, useEffect } from 'react'
import { useGet } from '../../hooks/useGet'
import { RadBox } from '../../common/RadBox'
import { RadSpaceBetween } from '../../common/RadSpaceBetween'
import { RadHeader } from '../../common/RadHeader'
import { RadCheckbox } from '../../common/RadCheckbox'
import { RadLink } from '../../common/RadLink'
import { RadIcon } from '../../common/RadIcon'
import { formatNumber, formatDate } from '../../common/utilities'

export function MessageThread ({
  personId,
  conversationSid,
  messages,
  selecting,
  selectedMessages,
  setSelectedMessages,
  searchParams
}) {
  const endOfChat = useRef(null)
  const target = useRef(null)
  const targetSid = searchParams?.get('message') ?? null

  useEffect(() => {
    if (target.current != null) {
      target.current.scrollIntoView({ behavior: 'auto' })
      return
    }

    if (endOfChat.current != null) {
      endOfChat.current.scrollIntoView({ behavior: 'auto' })
    }
  }, [messages])

  return messages.length > 0 && (
    <div className='messages'>
      <RadSpaceBetween size='xs' direction='vertical'>
        {messages
          .sort((a, b) => a.dateCreated - b.dateCreated)
          .map((message, i) =>
            <Message
              key={message.sid}
              message={message}
              i={i}
              targetSid={targetSid}
              target={target}
              selecting={selecting}
              selectedMessages={selectedMessages}
              setSelectedMessages={setSelectedMessages}
              personId={personId}
              messages={messages}
              conversationSid={conversationSid}
              endOfChat={endOfChat}
            />)}
      </RadSpaceBetween>
    </div>
  )
}

export function Message ({
  message,
  i,
  targetSid,
  target,
  selecting,
  selectedMessages,
  setSelectedMessages,
  personId,
  messages,
  conversationSid,
  endOfChat
}) {
  const { data: detection } = useGet(`/api/translate/detect-language?text=${message.body}`, true)
  const isUser = message.author.includes('@')
  const isTarget = targetSid === message.sid

  return (
    <RadBox
      key={message.sid}
      float={isUser ? 'right' : 'left'}
      className={`message-${isUser ? 'user' : 'student'}`}
    >
      {isTarget && (<div ref={target} />)}
      <RadSpaceBetween direction='vertical' size='xxs'>
        <RadHeader
          variant='h4'
          actions={
            <RadBox>
              {!selecting && isTarget && (
                <RadIcon variant='subtle' name='caret-left-filled' />
              )}
              {selecting && (
                <RadCheckbox
                  checked={selectedMessages.includes(message)}
                  onChange={({ detail }) => {
                    if (detail.checked) {
                      setSelectedMessages(s => [...s, message])
                    } else {
                      setSelectedMessages(s => s.filter(m => m.sid !== message.sid))
                    }
                  }}
                />
              )}
            </RadBox>
        }
        >
          <RadSpaceBetween direction='vertical' size='xxxxs'>
            <RadBox variant='h4'>
              {isUser ? message.author.split('|')[1] : formatNumber(message.author)}
            </RadBox>
            <RadBox variant='small'>
              <RadLink href={[
              `/student/${personId}/guardian-messages`,
              `?conversation=${conversationSid}`,
              `&message=${message.sid}`
              ].join('')}
              >
                {formatDate(message.dateCreated.toString(), true)}
              </RadLink>
            </RadBox>
          </RadSpaceBetween>
        </RadHeader>
        <RadBox variant='p'>
          <span style={{ whiteSpace: 'pre-line' }}>
            {message.body}
            {detection?.translation != null && <div>Translated from {detection.language}: {detection.translation}</div>}
          </span>
        </RadBox>
      </RadSpaceBetween>
      {i === messages.length - 1 && (<div ref={endOfChat} />)}
    </RadBox>
  )
}
